import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { InlineMessage } from "@find-truck-service/ui/src/components/react/InlineMessage";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { Message, messageTypes } from "@find-truck-service/ui/src/components/react/Message";
import { getListingImageSrc } from "@find-truck-service/ui/src/components/react/ListingItem/helpers";
import { inlineMessageSizes, inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
import FeaturedVendorsSliderMobile from "@find-truck-service/ui/src/components/react/FeaturedVendorsSliderMobile";
import { ListingItemImage } from "@find-truck-service/ui/src/components/react/ListingItem/Components/ListingItemImage";
import { ListingContactsRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingContactsRow";
import { ListingDescriptionRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingDescriptionRow";
import { ListingDetailsNameRow } from "@find-truck-service/ui/src/components/react/ListingDetailsModal/Components/ListingDetailsNameRow";
import { ListingWorkingHoursRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingWorkingHoursRow";
import { ListingDetailsAmenitiesRow } from "@find-truck-service/ui/src/components/react/ListingDetailsModal/Components/ListingDetailsAmenitiesRow";
import { ListingDetailsServicesAmenitiesRow } from "@find-truck-service/ui/src/components/react/ListingDetailsModal/Components/ListingDetailsServicesAmenitiesRow";
import { RichTextWrapperXs } from "@find-truck-service/ui/src/components/react/RichTextEditor/RichTextWrapperXs";
export const ListingDetailsContent = ({ listingData, featuredVendors }) => {
    const showImage = listingData?.price?.showImage;
    const showDescription = listingData?.price?.descriptionLines > 0;
    const hasFeatured = featuredVendors && featuredVendors.length > 0;
    const imageSrc = getListingImageSrc(listingData.approved, listingData.image);
    const amenities = listingData?.adsAmenities;
    const services = listingData?.adsServiceAmenities;
    const hasAmenities = !!amenities && amenities.length > 0;
    const hasServices = !!services && services.length > 0;
    if (!listingData)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { bg: ColorsValue["greyscale-50"], p: "0", pt: "xl", overflow: "auto" },
        showImage && (React.createElement(FtsWrapper, { px: "xl", mb: "2xl" },
            React.createElement(ListingItemImage, { width: "100%", height: "100%", src: imageSrc, priceId: listingData.price?.id }))),
        !showImage && (React.createElement(FtsWrapper, { px: "xl", pb: "2xl" },
            React.createElement(Message, { type: messageTypes.warning, title: "Image not available for this listing" }))),
        hasFeatured && (React.createElement(React.Fragment, null,
            React.createElement(FtsWrapper, { px: "xl", pb: "md" },
                React.createElement(InlineMessage, { size: inlineMessageSizes.md, type: inlineMessageTypes.warning, message: "Premium vendors visible on free listings." })),
            React.createElement(FeaturedVendorsSliderMobile, { featuredVendors: featuredVendors }))),
        React.createElement(ListingDetailsNameRow, { ...listingData }),
        React.createElement(ListingContactsRow, { fax: listingData.fax || "", website: listingData.website || "", afterHours: listingData.afterHours || "", phoneNumber: listingData.phoneNumber || "", phoneNumberSecondary: listingData.phoneNumberSecondary || "", showWebsite: !!listingData.price?.showWebsite && !!listingData.website }),
        showDescription && (React.createElement(RichTextWrapperXs, null,
            React.createElement(ListingDescriptionRow, { price: listingData.price, priceId: listingData.price?.id, description: listingData.description || "" }))),
        React.createElement(ListingWorkingHoursRow, { mobileRoadServiceOpen: !!listingData.mobileRoadServiceOpen, towingRecoveryOpen: !!listingData.towingRecoveryOpen, specialHours: listingData.specialHours, alwaysOpen: !!listingData.alwaysOpen, monday: listingData.workingHours?.monday || "", tuesday: listingData.workingHours?.tuesday || "", wednesday: listingData.workingHours?.wednesday || "", thursday: listingData.workingHours?.thursday || "", friday: listingData.workingHours?.friday || "", saturday: listingData.workingHours?.saturday || "", sunday: listingData.workingHours?.sunday || "" }),
        hasServices && React.createElement(ListingDetailsServicesAmenitiesRow, { listingServiceAmenities: services }),
        hasAmenities && React.createElement(ListingDetailsAmenitiesRow, { listingAmenities: amenities })));
};
