import { FREE_LISTING_PRICE_ID, IMAGE_SIZES, PREMIUM_LISTING_PRICE_ID } from "./constants";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const MAX_DESCRIPTION_LINE = 25;
// MAX_DESCRIPTION_LINE * line_height (20px) = 500px
export const MAX_DESCRIPTION_HEIGHT = 500;
export const getImageSize = (typeId) => {
    return typeId ? IMAGE_SIZES[typeId] : { width: 295, height: 65 };
};
export const getHeightByPriceId = (priceId) => {
    switch (priceId) {
        case 3:
            return 60;
        case 2:
            return 120;
        case 1:
            return 240;
        default:
            return MAX_DESCRIPTION_HEIGHT;
    }
};
export const getListingItemBg = (priceId) => {
    return priceId === FREE_LISTING_PRICE_ID ? ColorsValue["transparent"] : ColorsValue["greyscale-0"];
};
export const getBoxShadow = (priceId) => {
    return priceId === FREE_LISTING_PRICE_ID ? undefined : "sm";
};
export const getShowBenefits = (priceId) => {
    return priceId !== FREE_LISTING_PRICE_ID;
};
export const getShowPrice = (priceId) => {
    return priceId !== FREE_LISTING_PRICE_ID;
};
export const getShowMostPopularTag = (priceId) => {
    return priceId === PREMIUM_LISTING_PRICE_ID;
};
const formatImageLink = (fileUrl) => {
    if (!fileUrl)
        return "";
    if (fileUrl.startsWith("http"))
        return fileUrl;
    if (!fileUrl.startsWith("/"))
        fileUrl = "/" + fileUrl;
    return process.env.REACT_APP_IMAGE_URL + fileUrl.replace("../", "");
};
export function getListingImageSrc(approved, image) {
    return approved?.toLowerCase() === "yes" ? formatImageLink(image) : "";
}
